.quill-editor {
    width: 100%; /* 에디터 너비를 100%로 설정 */
    min-height: 300px; /* 최소 높이를 300px로 설정 */
    max-height: 500px; /* 최대 높이를 500px로 설정 */
    overflow-y: auto; /* 세로 오버플로우를 자동으로 설정 */
}

.quill-editor .ql-editor {
    min-height: 300px; /* 편집 영역의 최소 높이를 300px로 설정 */
}

.prose ul {
    list-style-type: disc; /* 순서 없는 목록의 마커를 원형으로 설정 */

}
.prose li {
    margin-left: 1.5em; /* 목록 항목의 왼쪽 여백을 1.5em으로 설정 */
}

.prose ol {
    list-style-type: decimal; /* 순서 있는 목록의 마커를 숫자로 설정 */
}

.prose div {
    margin-top: 1em; /* div 요소의 하단 여백을 1em으로 설정 */
}

.ql-snow h1 {
    font-size: 2em !important;
}
  
.ql-snow h2 {
    font-size: 1.6em !important;
}
  
.ql-snow h3 {
    font-size: 1.2em !important;
}

.ql-size-huge {
    font-size: 4em !important;
}

.ql-size-large {
    font-size: 2em !important;
}

.ql-size-normal {
    font-size: 1em !important;
}

.ql-size-small {
    font-size: 0.75em !important;
}

.ql-font-serif {
    font-family: 'serif' !important;
}

.ql-font-monospace {
    font-family: 'monospace' !important;
}

.ql-indent-1 {
    padding-left: 3em !important;
}

.ql-indent-2 {
    padding-left: 6em !important;
}

.ql-indent-3 {
    padding-left: 9em !important;
}

